<template>
  <div>
    <v-dialog v-model="isVisibleValidationErrorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{ errorMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-if="helpDialog" v-model="helpDialog">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Help</span>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <pre class="title">{{ affInfo.comment }}</pre>
                <v-img v-if="affInfo.screenshot" :src="require(`@/assets/api/doc/${affInfo.screenshot}`)">
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        right
        top
        color="green darken-2"
    >
      Copied : {{ snackbarText }}
    </v-snackbar>

    <v-dialog v-model="dialog" :persistent="copy || !internalView" max-width="900">
      <template v-slot:activator="{ on, attrs }">
        <slot name="activator" v-bind:on="on" v-bind="attrs"></slot>
      </template>
      <v-card>

        <v-card-title class="headline" v-if="copy">
          Copy Flow
        </v-card-title>

        <v-card-title class="headline" v-else-if="internalView">
          View Flow
        </v-card-title>

        <v-card-title class="headline" v-else>
          Create Flow
        </v-card-title>

        <v-card-text>
          <v-form v-model="valid" :readonly="internalView">
            <v-container fluid>
              <!--                <v-container class="pt-0">-->
              <v-row>
                <v-col v-if="internalView" cols="12" sm="6">
                  {{ thisFlowId }}
                  <v-btn icon small @click="copyToClipboard(thisFlowId)">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>

                </v-col>

                <v-col v-if="internalView" cols="12" sm="6" align="right">
                  {{ thisTimestamp }}
                </v-col>

                <!--              <v-col v-else cols="12" sm="6" align="right">-->

                <!--              </v-col>-->
                <v-col cols="12" sm="2">
                  <v-autocomplete
                      label="Who add"
                      dense
                      v-model="thisUser"
                      :items="users"
                      auto-select-first
                      required
                      :rules="[(v) => v !== null && v.length >0]"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="2">
                  <v-autocomplete
                      label="Creator"
                      dense
                      v-model="thisCreator"
                      :items="users"
                      auto-select-first
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-text-field
                      label="Offer name"
                      dense
                      v-model="thisOfferName"
                      required
                      :rules="[(v) => v !== null && v.length >0]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <v-autocomplete
                      label="GEO"
                      dense
                      v-model="thisCountry"
                      :items="countries"
                      auto-select-first
                      required
                      :rules="[validateGeo]"
                  >
                    <template v-slot:prepend>
                      <flag :iso="thisCountry"/>
                    </template>

                    <template v-slot:item="{ item }">
                      <flag :iso="item.value" class="mr-3"/>
                      {{ item.text }}
                    </template>

                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="2">
                  <v-text-field
                      label="Postfix"
                      dense
                      v-model="thisPostfix"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" align="center">
                <v-col cols="12" sm="2">
                  <v-autocomplete
                      label="Model"
                      v-model="thisModel"
                      :items="['CPC', 'DSP']"
                      auto-select-first
                      required
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" v-if="thisModel === 'DSP'">
                  <v-autocomplete
                      label="DSP Network"
                      v-model="thisSource"
                      :items="dspList"
                      auto-select-first
                      required
                      return-object
                      :rules="[validateSource]"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-autocomplete
                      label="Affiliate network"
                      v-model="thisAffId"
                      :items="thisAffiliateNetworks"
                      auto-select-first
                      required
                      :rules="[(v) => v !== null && v.length >0]"
                  >
                    <template v-slot:append-outer v-if="!internalView">
                      <v-btn icon small @click="helpDialog = true"
                             :disabled="thisAffId === null || thisAffId.length === 0">
                        <v-icon small>mdi-help</v-icon>
                      </v-btn>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" :sm="item.size" v-for="item in affField.fields" :key="item.name">
                  <v-text-field
                      v-if="!(Object.prototype.hasOwnProperty.call(item, 'hide') && item.hide())"
                      required
                      :label="item.name"
                      dense
                      @input="item.setValue(affField.data, $event)"
                      :rules="[item.rules]"
                      :value="item.getValue(affField.data)"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="!internalView && (thisAffId !== null && thisAffId.length > 0)">
                <v-col cols="12">
                  <pre class="subtitle-1">{{ affInfo.comment }}</pre>
                </v-col>
              </v-row>

            </v-container>

          </v-form>
        </v-card-text>
        <v-card-actions v-if="!internalView">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" :disabled="!valid" @click="onCreate">
            Create
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import api from '@/utils/api'

export default {
  name: 'FlowWindow',
  props: {
    countries: {default: []},
    users: {default: []},
    value: {
      default: false
    },
    copy: {
      default: false
    },
    view: {
      default: false
    },
    flow: {
      default: {}
    },
    affiliateNetworks: {
      default: []
    },
    dspList: {
      default: []
    }
  },
  data () {
    return {
      internalView: false,
      dialog: this.value,
      snackbar: false,
      snackbarText: '',
      valid: false,
      isVisibleValidationErrorDialog: false,
      errorMessage: '',

      helpDialog: false,
      helpMessage: '',
      helpImage: '',

      thisTimestamp: null,
      thisFlowId: null,
      thisUser: null,
      thisCreator: null,
      thisOfferName: null,
      thisCountry: null,
      thisPostfix: null,
      thisSource: null,

      thisModel: 'CPC',
      thisAffId: null,

      thisAffiliateNetworks: [],

      affField: {},
      affFields: {
        default: {
          fields: [],
          data: {}
        },
        adcombo: {
          fields: [
            {
              name: 'ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Price',
              getValue: (flow) => flow['aff_settings']['price'],
              setValue: (flow, value) => flow['aff_settings']['price'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Tracking URL',
              getValue: (flow) => flow['aff_settings']['base_url'],
              setValue: (flow, value) => flow['aff_settings']['base_url'] = value,
              rules: this.validateURL,
              size: 12
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              base_url: null,
              price: null
            }
          }
        },
        // adcombo_shynderyk: {
        //   fields: [
        //     {
        //       name: 'ID',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateNumber,
        //       size: 3
        //     },
        //     {
        //       name: 'Price',
        //       getValue: (flow) => flow['aff_settings']['price'],
        //       setValue: (flow, value) => flow['aff_settings']['price'] = value,
        //       rules: this.validateNumber,
        //       size: 3
        //     },
        //     {
        //       name: 'Tracking URL',
        //       getValue: (flow) => flow['aff_settings']['base_url'],
        //       setValue: (flow, value) => flow['aff_settings']['base_url'] = value,
        //       rules: this.validateURL,
        //       size: 12
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //     aff_settings: {
        //       base_url: null,
        //       price: null
        //     }
        //   }
        // },
        Affscale: {
          fields: [
            {
              name: 'Goal',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value.toString(),
              rules: this.validateNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        Auron: {
          fields: [
            {
              name: 'Goal',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value.toString(),
              rules: this.validateNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        Dinos: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            }
            // ,
            // {
            //   name: 'Goal ID',
            //   getValue: (flow) => flow['aff_settings']['goal_id'],
            //   setValue: (flow, value) => flow['aff_settings']['goal_id'] = value,
            //   rules: this.validateNumber,
            //   size: 3
            // }
          ],
          data: {
            offer_id: null,
            // aff_settings: {
            //   goal_id: null
            // }
          }
        },
        ApiMonadLead: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            }
            // ,
            // {
            //   name: 'Goal ID',
            //   getValue: (flow) => flow['aff_settings']['goal_id'],
            //   setValue: (flow, value) => flow['aff_settings']['goal_id'] = value,
            //   rules: this.validateNumber,
            //   size: 3
            // }
          ],
          data: {
            offer_id: null,
            // aff_settings: {
            //   goal_id: null
            // }
          }
        },
        // 'Affscale-shynderyk': {
        //   fields: [
        //     {
        //       name: 'Goal',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateNumber,
        //       size: 3
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //   }
        // },
        // 'Affscale-miro': {
        //   fields: [
        //     {
        //       name: 'Goal',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateNumber,
        //       size: 3
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //   }
        // },
        CPAgetti: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Stream Code',
              getValue: (flow) => flow['aff_settings']['stream_code'],
              setValue: (flow, value) => flow['aff_settings']['stream_code'] = value,
              rules: this.validateString,
              size: 3
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              stream_code: null,
              lang: () => this.thisCountry.toUpperCase(),
            }
          }
        },
        // 'CPAgetti-shynderyk': {
        //   fields: [
        //     {
        //       name: 'Offer ID',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateNumber,
        //       size: 3
        //     },
        //     {
        //       name: 'Stream Code',
        //       getValue: (flow) => flow['aff_settings']['stream_code'],
        //       setValue: (flow, value) => flow['aff_settings']['stream_code'] = value,
        //       rules: this.validateString,
        //       size: 3
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //     aff_settings: {
        //       stream_code: null
        //     }
        //   }
        // },
        // LeadRock_vera: {
        //   fields: [
        //     {
        //       name: 'Offer ID',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateNumber,
        //       size: 3
        //     },
        //     {
        //       name: 'Link',
        //       getValue: (flow) => flow['aff_settings']['base_url'],
        //       setValue: (flow, value) => flow['aff_settings']['base_url'] = value,
        //       rules: this.validateURL,
        //       size: 12
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //     aff_settings: {
        //       base_url: null
        //     }
        //   }
        // },
        LeadRock: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Link',
              getValue: (flow) => flow['aff_settings']['base_url'],
              setValue: (flow, value) => flow['aff_settings']['base_url'] = value,
              rules: this.validateURL,
              size: 12
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              base_url: null
            }
          }
        },
        LuckyOnline: {
          fields: [
            {
              name: 'Flow Hash',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateUUID,
              size: 12
            }
          ],
          data: {
            offer_id: null,
          }
        },
        MonadvertNew: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Goal ID',
              getValue: (flow) => flow['aff_settings']['goal_id'],
              setValue: (flow, value) => flow['aff_settings']['goal_id'] = value,
              rules: this.validateNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              goal_id: null
            }
          }
        },
        Monadvert: {
          fields: [
            {
              name: 'Flow ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateStringWithMinus,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },

        Ikon: {
          fields: [
            {
              name: 'Flow ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateString,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        DrCash: {
          fields: [
            {
              name: 'Campaign CODE',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateString,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        // 'DrCash-sanlandemon': {
        //   fields: [
        //     {
        //       name: 'Campaign CODE',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateString,
        //       size: 3
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //   }
        // },
        // 'DrCash-hotpotloverd': {
        //   fields: [
        //     {
        //       name: 'Campaign CODE',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateString,
        //       size: 3
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //   }
        // },
        everad: {
          fields: [
            {
              name: 'Campaign ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        // Monadvert: {
        //   fields: [
        //     {
        //       name: 'Offer ID',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateRtbString,
        //       size: 6
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //   }
        // },
        Limonad: {
          fields: [
            {
              name: 'ID ( UUID )',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateUUID,
              size: 12
            }
          ],
          data: {
            offer_id: null,
          }
        },
        Affbay: {
          fields: [
            {
              name: 'SKU UUID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateUUID,
              size: 12
            }
          ],
          data: {
            offer_id: null,
          }
        },
        // 'Affbay-affman': {
        //   fields: [
        //     {
        //       name: 'SKU UUID',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateUUID,
        //       size: 12
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //   }
        // },
        Aff1: {
          fields: [{
            name: 'target_hash',
            getValue: (flow) => flow['aff_settings']['target_hash'],
            setValue: (flow, value) => flow['aff_settings']['target_hash'] = value,
            rules: this.validateString,
            size: 3
          },
            {
              name: 'Flow ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateString,
              size: 3
            },

          ],
          data: {
            offer_id: null,
            aff_settings: {
              target_hash: null
            }
          }
        },
        LeadReaktor: {
          fields: [
            {
              name: 'Flow ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        LeadBit: {
          fields: [
            {
              name: 'Flow ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateString,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        // 'LeadBit-shynderyk': {
        //   fields: [
        //     {
        //       name: 'Flow ID',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateString,
        //       size: 3
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //   }
        // },
        // LeadBit: {
        //   fields: [
        //     {
        //       name: 'Flow ID',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateString,
        //       size: 3
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //   }
        // },
        Broleads: {
          fields: [
            {
              name: 'Flow ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateString,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        WebVork: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Stream ID',
              getValue: (flow) => flow['aff_settings']['stream_code'],
              setValue: (flow, value) => flow['aff_settings']['stream_code'] = value,
              rules: this.validateNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              stream_code: null
            }
          }
        },
        Riddick: {
          fields: [
            {
              name: 'Stream UUID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateUUID,
              size: 12
            }
          ],
          data: {
            offer_id: null,
          }
        },
        Affstar: {
          fields: [
            {
              name: 'Flow UUID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateUUID,
              size: 12
            }
          ],
          data: {
            offer_id: null,
          }
        },
        Trackbox: {
          fields: [
            {
              name: 'Flow ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateString,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        Shakes: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateString,
              size: 3
            },
            {
              name: 'Stream Code',
              getValue: (flow) => flow['aff_settings']['streamCode'],
              setValue: (flow, value) => flow['aff_settings']['streamCode'] = value,
              rules: this.validateString,
              size: 3
            },
            {
              name: 'Landing URL',
              getValue: (flow) => flow['aff_settings']['landingUrl'],
              setValue: (flow, value) => flow['aff_settings']['landingUrl'] = value,
              rules: this.validateURL,
              size: 12
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              streamCode: null,
              landingUrl: null
            }
          }
        },
        RocketProfit: {
          fields: [
            {
              name: 'Campaign ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        Skylead: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Flow ID',
              getValue: (flow) => flow['aff_settings']['flow'],
              setValue: (flow, value) => flow['aff_settings']['flow'] = value,
              rules: this.validateNumber,
              size: 3,
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              flow: null
            }
          }
        },
        KMA: {
          fields: [
            {
              name: 'Stream ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateString,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        // 'KMA-hotpotloverd': {
        //   fields: [
        //     {
        //       name: 'Stream ID',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateString,
        //       size: 3
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //   }
        // },
        Affiliateninjas: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        TrafficLight: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Stream hid',
              getValue: (flow) => flow['aff_settings']['stream_hid'],
              setValue: (flow, value) => flow['aff_settings']['stream_hid'] = value,
              rules: this.validateString,
              size: 3
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              stream_hid: null
            }
          }
        },
        Terraleads: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Stream ID',
              getValue: (flow) => flow['aff_settings']['stream_id'],
              setValue: (flow, value) => flow['aff_settings']['stream_id'] = value,
              rules: this.validateNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              stream_id: null
            }
          }
        },
        // 'Terraleads-aleksandrd': {
        //   fields: [
        //     {
        //       name: 'Offer ID',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateNumber,
        //       size: 3
        //     },
        //     {
        //       name: 'Stream ID',
        //       getValue: (flow) => flow['aff_settings']['stream_id'],
        //       setValue: (flow, value) => flow['aff_settings']['stream_id'] = value,
        //       rules: this.validateNumber,
        //       size: 3
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //     aff_settings: {
        //       stream_id: null
        //     }
        //   }
        // },
        Umgid: {
          fields: [
            {
              name: 'API key',
              getValue: (flow) => flow['aff_settings']['key'],
              setValue: (flow, value) => flow['aff_settings']['key'] = value,
              rules: this.validateString,
              size: 3
            },
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Product ID',
              getValue: (flow) => flow['aff_settings']['product_id'],
              setValue: (flow, value) => flow['aff_settings']['product_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Payment ID',
              getValue: (flow) => flow['aff_settings']['payment_id'],
              setValue: (flow, value) => flow['aff_settings']['payment_id'] = value,
              rules: this.validateNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              key: null,
              product_id: null,
              payment_id: null
            }
          }
        },
        M1Show: {
          fields: [
            {
              name: 'Product ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null
          }
        },
        Azurelab: {
          fields: [
            {
              name: 'Product ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => {
                flow['offer_id'] = value.toString()
              },
              rules: (value) => {
                if (value === null) return false
                const offers = ['33', '19', '20', '22', '23', '34', '37', '38', '42', '44']
                return offers.includes(value.toString())
              },
              size: 3,
            },
            {
              name: 'GEO code',
              getValue: (flow) => {
                const geo = {'1': 'RO', '2': 'CZ', '4': 'PL', '6': 'IT'}
                return geo[flow['aff_settings']['geo_code']]
              },
              setValue: (data, value) => data['aff_settings']['geo_code'] = value,
              rules: () => true,
              hide: () => true,
              size: 3,
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              geo_code: null
            }
          }
        },
        ProfitPay: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Flow ID',
              getValue: (flow) => flow['aff_settings']['flow'],
              setValue: (flow, value) => flow['aff_settings']['flow'] = value,
              rules: this.validateNumber,
              size: 3,
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              flow: null
            }
          }
        },
        StackMoney: {
          fields: [
            {
              name: 'Offer ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value,
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Flow ID',
              getValue: (flow) => flow['aff_settings']['flow'],
              setValue: (flow, value) => flow['aff_settings']['flow'] = value,
              rules: this.validateNumber,
              size: 3,
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              flow: null
            }
          }
        },
        Aivix: {
          fields: [],
          data: {}
        },
        Affiliates360: {
          fields: [],
          data: {}
        },
        Roibees: {
          fields: [],
          data: {}
        },
        Lagoon: {
          fields: [
            {
              name: 'Flow ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value.toString(),
              rules: this.validateStringAndNumber,
              size: 3
            }
          ],
          data: {
            offer_id: null,
          }
        },
        Leadbusters: {
          fields: [
            {
              name: 'Tracking URL',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value.toString().trim(),
              rules: this.validateURL,
              size: 6
            }
          ],
          data: {
            offer_id: null,
          }
        },
        Clickhub: {
          fields: [
            {
              name: 'product code',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value.toString().trim(),
              rules: this.validateNumber,
              size: 6
            },
            {
              name: 'Product title',
              getValue: (flow) => flow['aff_settings']['product_title'],
              setValue: (flow, value) => flow['aff_settings']['product_title'] = value,
              rules: this.validateAnyString,
              size: 6
            },
            {
              name: 'Price',
              getValue: (flow) => flow['aff_settings']['price'],
              setValue: (flow, value) => flow['aff_settings']['price'] = value,
              rules: this.validateFloatAndInt,
              size: 3
            },
            {
              name: 'Currency',
              getValue: (flow) => flow['aff_settings']['currency'],
              setValue: (flow, value) => flow['aff_settings']['currency'] = value,
              rules: this.validateString,
              size: 3
            },
            {
              name: 'Locale',
              getValue: (flow) => flow['aff_settings']['locale'],
              setValue: (flow, value) => flow['aff_settings']['locale'] = value,
              rules: this.validateLocaleForClickHub,
              size: 3
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              price: null,
              currency: null,
              locale: null,
              product_title: null
            }
          }
        },
        Stepmode: {
          fields: [
            {
              name: 'ID',
              getValue: (flow) => flow['offer_id'],
              setValue: (flow, value) => flow['offer_id'] = value.toString(),
              rules: this.validateNumber,
              size: 3
            },
            {
              name: 'Price',
              getValue: (flow) => flow['aff_settings']['price'],
              setValue: (flow, value) => flow['aff_settings']['price'] = value,
              rules: this.validateFloatAndInt,
              size: 3
            }
          ],
          data: {
            offer_id: null,
            aff_settings: {
              price: null
            }
          }
        }
        // ProfitPay_hotpotloverd: {
        //   fields: [
        //     {
        //       name: 'Offer ID',
        //       getValue: (flow) => flow['offer_id'],
        //       setValue: (flow, value) => flow['offer_id'] = value,
        //       rules: this.validateNumber,
        //       size: 3
        //     },
        //     {
        //       name: 'Flow ID',
        //       getValue: (flow) => flow['aff_settings']['flow'],
        //       setValue: (flow, value) => flow['aff_settings']['flow'] = value,
        //       rules: this.validateNumber,
        //       size: 3
        //     }
        //   ],
        //   data: {
        //     offer_id: null,
        //     aff_settings: {
        //       flow: null
        //     }
        //   }
        // },
      }
    }
  },
  created () {
    // console.log('flowflowflowflow', this.flow)
    // console.log('affiliateNetworks', this.affiliateNetworks)
    // console.log('dspList', this.dspList)

    this.internalView = Boolean(this.view)

    if (this.view) {
      this.thisTimestamp = this.dateFormat(this.flow['@timestamp'])
      this.thisFlowId = this.flow._id
      this.thisUser = this.flow.user_id
      this.thisCreator = this.flow.creator
      this.thisOfferName = this.flow.offer_name
      this.thisCountry = this.flow.country_code.toLowerCase()
      this.thisPostfix = this.flow.postfix
      this.thisAffId = this.flow.aff_id
      this.thisAffiliateNetworks = [this.thisAffId]

      if (Object.prototype.hasOwnProperty.call(this.flow, 'model') && (this.flow.model === 'BTS' || this.flow.model === 'CPM')) {
        this.thisModel = 'DSP'
        if (Object.prototype.hasOwnProperty.call(this.flow, 'source')) {
          this.thisSource = this.flow.source
        } else {
          this.thisSource = 'Mgid'
        }
      }

      // const searchRe = new RegExp(`(^${this.thisAffId})-?`)

      const aff = Object.entries(this.affFields).find(([k]) => {
        // const m = k.match(searchRe)
        const m = this.thisAffId.match(new RegExp(`(^${k})[-_]?`))
        if (m) {
          return m[1]
        }
      })



      // console.log('aff', aff)

      if (aff) {
        this.affField = this.affFields[aff[0]]
        this.affField.data = this.flow
      } else {
        this.affField = this.affFields.default
        this.affField.data = this.flow
      }
    } else {
      this.thisAffiliateNetworks = this.affiliateNetworks
      this.affField = this.affFields.default
      this.affField.data = this.flow
    }
  },
  computed: {
    // affField () {
    //   if (Object.prototype.hasOwnProperty.call(this.affFields, this.thisAffId)) {
    //     return this.affFields[this.thisAffId]
    //   } else {
    //     return this.affFields.default
    //   }
    // },
    // thisModel: {
    //   get () {
    //     if (Object.prototype.hasOwnProperty.call(this.flow, 'model') && this.flow.model === 'BTS') {
    //       return 'DSP'
    //     }
    //     return 'CPC'
    //   },
    //   set (v) {
    //     this.model = v
    //   }
    // }

    affInfo () {
      return this.thisAffiliateNetworks.find(n => n.value === this.thisAffId)
    }
  },
  methods: {
    dateFormat (unixtime) {
      // return DateTime.fromMillis(unixtime*1000).toFormat('dd-LL-y - H:mm')
      return DateTime.fromSeconds(unixtime).toLocaleString({...DateTime.DATE_MED, ...DateTime.TIME_SIMPLE})
    },

    copyToClipboard (text) {
      this.$copyText(text)
      this.snackbarText = text
      this.snackbar = true
    },

    validateNumber (value) {
      if (!/^\d+$/.test(value)) {
        return 'Only digit'
      }
      return true
    },
    validateFloatAndInt (value) {
      if (!/^\d+(\.\d+)?$/.test(value)) {
        return 'Only float or int'
      }
      return true
    },


    validateLocaleForClickHub (value) {
      if (!(['de_AT', 'nl_BE', 'pt_BR', 'bg_BG', 'es_CO', 'hr_HR', 'cs_CZ', 'da_DK', 'en_GB', 'et_EE', 'fi_FI', 'fr_FR', 'de_DE', 'el_GR', 'hu_HU', 'it_IT', 'lv_LV', 'lt_LT', 'fr_LU', 'es_MX', 'nl_NL', 'no_NO', 'pl_PL', 'pt_PT', '_XS', 'ro_RO', 'ru_RU', 'sk_SK', 'sl_SI', 'es_ES', 'sv_SE', 'de_CH', 'th_TH', 'tr_TR', 'ar_AE'].includes(value))) {
        return 'Unknown locale'
      }
      return true
    },

    validateString (value) {
      if (typeof value === 'string' && value.length > 0) {
        if (!/^\w+$/i.test(value)) {
          return 'Need string'
        }
      } else {
        return 'Need string'
      }
      return true
    },

    validateAnyString (value) {
      if (!(typeof value === 'string' && value.length > 0)) {
        return 'Need string'
      }
      return true
    },

    validateStringAndNumber (value) {
      if (typeof value === 'string' && value.length > 0) {
        if (!/^[\d\w]+$/i.test(value)) {
          return 'Need string'
        }
      } else {
        return 'Need string'
      }
      return true
    },

    validateStringWithMinus (value) {
      if (typeof value === 'string' && value.length > 0) {
        if (!/^[\w-]+$/i.test(value)) {
          return 'Need string'
        }
      } else {
        return 'Need string'
      }
      return true
    },

    validateRtbString (value) {
      if (typeof value === 'string' && value.length > 0) {
        if (!/-RTB-/i.test(value)) {
          return 'Need RTB offer from doc'
        }
      } else {
        return 'Need RTB offer from doc'
      }
      return true
    },

    validateUUID (value) {
      if (typeof value === 'string' && value.length > 0) {
        if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value)) {
          return 'Need UUID'
        }
      } else {
        return 'Need UUID'
      }
      return true
    },

    validateURL (value) {
      if (value === null || !/^https?:\/\/.*/i.test(value)) {
        return 'Need URL'
      }
      return true
    },

    validateGeo (value) {
      if (this.thisAffId === 'Azurelab') {
        const geo = {'ro': '1', 'cz': '2', 'pl': '4', 'it': '6'}
        switch (value) {
          case 'ro':
          case 'cz':
          case 'pl':
          case 'it':
            this.affField.data.aff_settings.geo_code = geo[value]
            return true
          default:
            return 'Error GEO'
        }
      } else {
        return value !== null && value.length > 0
      }
    },

    async onCreate () {
      // console.log('onCreate', this.thisUser)
      // console.log('onCreate', this.thisOfferName)
      // console.log('onCreate', this.thisCountry)
      // console.log('onCreate', this.thisPostfix)
      // console.log('onCreate', this.thisModel)
      // console.log('onCreate', this.thisAffId)
      // console.log('onCreate', this.affField.data)

      const flow = {
        aff_id: this.thisAffId.toString().trim(),
        user_id: this.thisUser.toString().trim(),
        offer_name: this.thisOfferName.toString().trim(),
        country_code: this.thisCountry.toString().trim().toUpperCase(),
      }

      if (Object.prototype.hasOwnProperty.call(this.affField.data, 'offer_id')) {
        flow.offer_id = this.affField.data.offer_id.toString().trim()
      }

      if (this.thisModel === 'DSP') {
        flow.model = 'CPM'
        if (this.thisSource !== 'Mgid') {
          flow.source = this.thisSource.value
          flow.code = this.thisSource.code
        }
      } else {
        flow.model = 'CPC'
      }

      if (this.thisCreator) {
        flow.creator = this.thisCreator
      }

      if ((typeof this.thisPostfix === 'string' && this.thisPostfix.length > 0) ||
          typeof this.thisPostfix === 'number') {
        flow.postfix = this.thisPostfix.toString().trim()
      }

      // console.log('onCreate Request', this.affField)

      if (Object.prototype.hasOwnProperty.call(this.affField.data, 'aff_settings')) {
        flow.aff_settings = {}
        Object.keys(this.affField.data.aff_settings).forEach((k) => {
              // console.log(k, this.affField.data.aff_settings[k])
              if (typeof this.affField.data.aff_settings[k] === 'function') {
                flow.aff_settings[k] = this.affField.data.aff_settings[k]().toString().trim()
              } else {
                flow.aff_settings[k] = this.affField.data.aff_settings[k].toString().trim()
              }
            }
        )
      }

      // console.log('onCreate Request', this.affField)
      // console.log('onCreate Request', JSON.stringify(flow, null, 2))

      try {
        const res = await api.flowCreate(flow)
        if (!res.data.error) {
          console.log('Flow id:', res.data.flowId)
          this.thisFlowId = res.data.flowId
          this.thisTimestamp = this.dateFormat(res.data.timestamp)
          this.internalView = true
          this.$emit('update', false)
          // this.dialog = false
          // this.$emit('input', false)
        } else {
          this.errorMessage = res.data.message
          this.isVisibleValidationErrorDialog = true
        }
      } catch (e) {
        console.log(e)
      }
    },

    validateSource (source) {
      console.log('validateSource', source)
      if (this.thisModel === 'CPC') {
        return true
      }
      if (source === null || typeof source !== 'object' && !Object.hasOwnProperty.call(source, 'value')) {
        return false
      }
      return (source.value !== null && source.value.length > 0)
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.$emit('input', false)
      }
    },
    value (value) {
      this.dialog = value
    },

    thisAffId (value) {
      console.log('thisAffId', value)
      if (!this.view) {
        const aff = Object.entries(this.affFields).find(([k]) => {
          const m = value.match(new RegExp(`(^${k})[-_]?`))
          if (m) {
            return k
          }
        })
        if (aff) {
          this.affField = this.affFields[aff[0]]
        } else {
          this.affField = this.affFields.default
        }
      }

    }
  }
}
</script>

<style scoped>

</style>